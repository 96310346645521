import { createTheme } from '@mui/material/styles';
import PoppinsBold from './assets/fonts/Poppins/Poppins-Bold.woff2';
import PoppinsRegular from './assets/fonts/Poppins/Poppins-Regular.woff2';
import PoppinsLight from './assets/fonts/Poppins/Poppins-Light.woff2';
import PoppinsMedium from './assets/fonts/Poppins/Poppins-Medium.woff2';
import PoppinsBoldItalic from './assets/fonts/Poppins/Poppins-BoldItalic.woff2';
import PoppinsItalic from './assets/fonts/Poppins/Poppins-Italic.woff2';
import PoppinsLightItalic from './assets/fonts/Poppins/Poppins-LightItalic.woff2';
import PoppinsMediumItalic from './assets/fonts/Poppins/Poppins-MediumItalic.woff2';

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#015099',
      light: '#063a70',
      dark: '#063a70',
    },
    secondary: {
      main: '#009a44',
    },
    background: {
      default: '#ffffff',
      paper: '#F1F4FB',
    },
    text: {
      primary: '#3A3A3A',
      secondary: 'white',
      hint: '#1B1F3C',
    },
    error: {
      main: '#da111b',
    },
    warning: {
      main: '#f5a507',
    },
    success: {
      main: '#86bd43',
    },
    transparent: {
      main: 'transparent',
      root: {
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 48,
        padding: '0 30px',
      },
    }
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: 'Poppins',
    fontSize: 16,
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      textTransform: 'capitalize',
    },
    h1: {
      fontSize: 42,
      fontWeight: 'bold',
      lineHeight: '64px',
    },
    h2: {
      fontSize: "26px",
      fontWeight: 'bold',
      lineHeight: '36px',
      marginBottom: "16px"
    },
    p: {
      fontSize: 16,
    },
  },
  shape: {
    borderRadius: 25,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: 'none',
          padding: '30px 0'
        }
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'dark' },
          style: {
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontSize: '1rem',
            background: 'linear-gradient(135deg, #095099 30%, #063a70 130%)',
            border: 0,
            borderRadius: 25,
            color: 'white',
            height: 48,
            padding: '0 30px',
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            fontSize: '1rem',
            backgroundColor: '#189A44',
            border: 0,
            borderRadius: 25,
            color: 'white',
            height: 48,
            padding: '0 30px',
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderRadius: 25,
            height: 48,
            padding: '0 30px',
          },
        },
        {
          props: { variant: 'dark', color: 'primary' },
          style: {
            fontSize: '1rem',
            backgroundColor: 'transparent',
            borderRadius: 25,
            border: '2px solid #ffffff',
            color: '#fff',
            height: 48,
            padding: '0 30px',
            boxShadow: 'none',
          },
        },
      ],
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& li': {
            fontSize: '16px',
            paddingLeft: 0,
            '& ul li svg': {
              color: '#878787',
            }
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'inherit',
          '& svg': {
            fontSize: '45px',
            color: '#DA0D17',
          }
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          src: local('Poppins'), local('Poppins-Bold'), url(${PoppinsBold}) format('woff2');
          font-weight: bold;
          font-display: swap;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsLight}) format('woff2');
          font-weight: 300;
          font-display: swap;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsMedium}) format('woff2');
          font-weight: 500;
          font-display: swap;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsRegular}) format('woff2');
          font-weight: normal;
          font-display: swap;
          font-style: normal;
        }
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsBoldItalic}) format('woff2');
          font-weight: bold;
          font-display: swap;
          font-style: italic;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsLightItalic}) format('woff2');
          font-weight: 300;
          font-display: swap;
          font-style: italic;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsMediumItalic}) format('woff2');
          font-weight: 500;
          font-display: swap;
          font-style: italic;
        }
        
        @font-face {
          font-family: 'Poppins';
          src: url(${PoppinsItalic}) format('woff2');
          font-weight: normal;
          font-display: swap;
          font-style: italic;
        }
        body {
          font-family: "Poppins", sans-serif;
        }
        .slick-dots li:not(.slick-active) .normal {
          display: none;
        }
        .slickGallery li:not(.slick-active) img {
          opacity: 0.2;
        }
        .slick-dots li.slick-active .dark {
          display: none;
        }
        .slick-dots li.slick-active .normal {
          display: block;
        }`,
    },
  },





});

export default theme;
